body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  background-color: #000000;
}

.navbar {
    //background-color: #FFFFFF !important;
    //oa-grey: #939497;
    background-color: #eb212e;
}

img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 90%;
    margin: 20px auto;
}
